<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="flex justify-between flex-wrap">
            <v-dialog v-model="dialog" max-width="600px">
              <template v-if="addUser" v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-2 mt-10 button"
                  dark
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  @click="reset"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add User
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="isEditing">Edit User</span>
                  <span class="headline" v-else>Add User</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <label for="">Full Name</label>
                          <v-text-field
                            filled
                            color="info"
                            v-model="userName"
                            :rules="emptyRules"
                          ></v-text-field>

                          <!-- <label for="">User Email</label>
                          <v-text-field
                            dense
                            outlined
                            v-model="companyDomain"
                            :rules="emptyRules"
                          ></v-text-field> -->

                          <div v-show="!isEditing">
                            <label for="">User Email</label>
                            <v-text-field
                              filled
                              color="info"
                              v-model="userEmail"
                              :rules="emailRules"
                            ></v-text-field>
                          </div>

                          <div>
                            <label for="">Date of Birth</label>
                            <v-text-field
                              filled
                              color="info"
                              v-model="userDateOfBirth"
                              type="date"
                            ></v-text-field>
                          </div>

                          <label for="">Address (Optional)</label>
                          <v-textarea
                            filled
                            color="info"
                            auto-grow
                            rows="1"
                            name="input-7-1"
                            hint="Hint text"
                            v-model="userAddress"
                          />

                          <v-select
                            v-model="userRoleId"
                            :items="roles"
                            filled
                            color="info"
                            item-text="name"
                            item-value="id"
                            label="Select Role"
                            chips
                            hint="Select the Role"
                            persistent-hint
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="m-2" color="error" @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!isEditing"
                        class="m-2"
                        color="info"
                        @click="save"
                        >Save User</v-btn
                      >
                      <v-btn v-else class="m-2" color="info" @click="editUser"
                        >Update User</v-btn
                      >
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-card>
            </v-dialog>

            <div></div>
          </div>
        </v-card-title>
        <mayventory-table
          :search="search"
          :headers="headers"
          :items="items"
          item-key="name"
          title="User"
          url="/app/user/view/"
        >
        </mayventory-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  GET_ALL_USERS,
  GET_ROLES,
  CREATE_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
} from "../../../constants/graphql";
import Swal from "sweetalert2";
import { handleError } from "../../../constants/error";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "User",
  },
  data() {
    return {
      isEditing: false,
      search: "",
      selected: [],
      userPermission: this.$store.state.authData.userPermissions,
      headers: [
        {
          text: "S/N",
          value: "sno",
          width: "10%",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Role", value: "role.name" },
        { text: "Action", value: "action" },
      ],
      items: [],
      userName: "",
      userEmail: "",
      userAddress: "",
      dialog: false,
      loading: false,
      userId: null,
      userDateOfBirth: 0,
      userRoleId: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      emptyRules: [(v) => !!v || "Field is required"],
      valid: true,
    };
  },
  computed: {
    addUser() {
      if (this.userPermission.includes("add_user")) {
        return true;
      }
    },
  },
  apollo: {
    items: {
      query: GET_ALL_USERS,
      update: (result) => result.getCompanyUsers,
      error(error) {
        handleError(error);
      },
    },
    roles: {
      query: GET_ROLES,
      update: (result) => result.getRoles,
      error(error) {
        handleError(error);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.$root.$refs.U = this;
  },

  methods: {
    close() {
      this.dialog = false;
      this.isEditing = false;
    },

    save() {
      let validated = this.$refs.form.validate();
      var date = this.userDateOfBirth.toString("yyyy-mm-dd");
      if (validated === true) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: CREATE_NEW_USER,
            variables: {
              name: this.userName,
              email: this.userEmail,
              dob: date,
              address: this.userAddress,
              roleId: this.userRoleId,
            },
          })
          .then((result) => {
            this.$apollo.queries.items.refetch();
            let resp = result.data.createNewUser;
            let dialogText = `
        <p>${resp.name} has been added to MayVentory successfully.</p>
        <p>Your details are:</p>
        <p><strong>Email:</strong> ${resp.email} </p>
        <p><strong>Password:</strong> ${resp.password}</p>
        <p><strong>Domain:</strong> ${resp.company.domain}</p>
        `;
            Swal.fire({
              html: `<div>${dialogText}</div>`,
              icon: "success",
              customClass: {
                popup: "format-pre",
              },
            });
          });
        this.close();
      }
    },

    reset() {
      this.userAddress = "";
      this.userDateOfBirth = "";
      this.userEmail = "";
      this.userName = "";
      this.userPassword = "";
      this.userRoleId = "";

      this.isEditing = false;
      this.dialog = true;
    },

    edit(item) {
      this.userName = item.name;
      this.userAddress = item.address;
      this.userId = item.id;
      this.userDateOfBirth = item.dob;
      this.userPassword = item.password;

      this.isEditing = true;
      this.dialog = true;

      this.$apollo.queries.items.refetch();
    },

    editUser() {
      this.loading = true;

      var date = this.userDateOfBirth;

      if (date == null) {
        var date = this.userDateOfBirth;
      } else {
        var date = this.userDateOfBirth.toString("yyyy-mm-dd");
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            userId: this.userId,
            name: this.userName,
            dob: date,
            address: this.userAddress,
            roleId: this.userRoleId,
          },
        })
        .then((_) => {
          Swal.fire({
            text: "User Update successful",
            icon: "success",
          });
        });
      this.close();
      this.$apollo.queries.items.refetch();
    },

    deleteUser(userId, userName) {
      this.$swal
        .fire({
          text: `Do you wish to delete ${userName}?`,
          icon: "question",
          showConfirmButton: true,
          confirmButtonText: "Yes",
          showDenyButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$apollo
              .mutate({
                mutation: DELETE_USER,
                variables: {
                  userId: userId,
                },
              })
              .then((_) => {
                this.$apollo.queries.items.refetch();
                this.$swal.fire({
                  toast: true,
                  text: `${userName} Deleted from MayVentory`,
                  icon: "success",
                  timer: 3000,
                  timerProgressBar: true,
                  position: "top-end",
                });
              });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
      td {
        padding: 0.95rem !important;
      }
    }
  }

  .format-pre pre {
    background: #49483e;
    color: #f7f7f7;
    padding: 10px;
    font-size: 14px;
  }
}
</style>
